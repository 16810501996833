import { render, staticRenderFns } from "./indexCardTable.vue?vue&type=template&id=368e23bf&scoped=true&"
import script from "./indexCardTable.vue?vue&type=script&lang=js&"
export * from "./indexCardTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368e23bf",
  null
  
)

export default component.exports