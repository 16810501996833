<template>
  <div class="lookUpModal">
    <modal
      :modelSize="'max'"
      :modelShow="modelShow"
      :zIndex="300"
      @open="open"
      @handelClose="$emit('handelClose')"
    >
      <template slot="body">
        <div class="content lookUpModal">
          <optionBar :schoolId="id" :showRiskCardState="false" 
          @change="changeOptionBar"  @queryClick="queryClick" @excelClick="excelClick"></optionBar>

        
          <cvue-table class="list-p"
            :option="tableOption"
            :data="tableData"
            ref="tbl1"
            :isShowAdd="false"
            :page="page"
            :tablePage="page.currentPage"
            :pageSize="page.pageSize"
            :loading="tableLoading"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :isShowPage="page.total > 0"
          >
              <template slot-scope="scope" slot="time">
                <div >{{scope.row.time | formatDateTime('yyyy-MM-dd HH:mm:ss')}}</div>
              </template>

              <template slot-scope="scope" slot="schoolName">
                <div >{{schoolName}}</div>
              </template>

              <template slot-scope="scope" slot="temperature">
                <div v-temperature="scope.row.temperature">{{scope.row.temperature}}℃</div>
              </template>
          </cvue-table>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { $reported, studentCardTemperaturePage, studentCardTemperatureExcel } from "@/api/defenceApi.js";
import Modal from "@/components/modal/modal";
import util from "@/util/timeFormat.js";
import cvueTable from "@/components/cvue-table";
import optionBar from '@/components/optionBar'
export default {
  name: "lookUp",

 
  components: {
    Modal,
    cvueTable,
    optionBar
  },
  props: {
    modelShow: Boolean,
    modelSize: String,
    id: {
      type: String,
      default: '3db7ed09e8c08982d43839f89e69881b'
    },
    date: String,
    schoolName: String,
  },
   computed: {
    countyUserInfo() {
      return this.$store.state.user.countyUserInfo;
    },
  },
  data() {
    return {
      tableLoading: false,
      page: {
        total: 0, // 总条目
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50],
      },

      tableOption: {
        stripe: true,
        width: "100%",
        border: false,
        index: false,
        cloumn: [
          { prop: "time", label: "时间", align: "center", slotStatus: true },
          { prop: "name", label: "姓名", align: "center" },
          { prop: "gradeName", label: "年级", align: "center" },
          { prop: "className", label: "班级", align: "center" },
          { prop: "schoolName", label: "学校", align: "center", slotStatus: true },
          { prop: "temperature", label: "体温", align: "center", slotStatus: true},
         
        ],
      },
      tableData: [],
    };
  },
  mounted() {},
  methods: {
    changeOptionBar(params){
           this.clas = params.clas
           this.grade = params.grade
           this.showMode = params.showMode
      },
      // 点击导出表格触发事件
      async excelClick () { 
        let params = {
          ShowMode: this.showMode,
          Date: this.date,
          Id: this.id
        }
        if (this.grade) params.GradeId = this.grade.id
        if (this.clas) params.ClassId = this.clas.id
        studentCardTemperatureExcel(params)
      },
      // 点击检索触发事件
      async queryClick (params) {
         this.page.currentPage = 1;
        this.getList();
        
      },


    // 改变pagesize
    handleSizeChange(val) {},
    // 改变页码
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getList();
    },
    open() {
     


    },
    async getList() { 
      
        let params = {
          ShowMode: this.showMode,
          Date: this.date,
          Id: this.id,
          Limit: this.page.pageSize,
          Page: this.page.currentPage,
          GradeId: this.grade.id,
          ClassId: this.clas.id
        }
        
        let list = await studentCardTemperaturePage(params)

        
        this.page.total = list.total
        this.tableData = list.records
        
    }
  },

  
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0.34rem 0.64rem 0;
}
.export-l {
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.list-p{
  margin: 0.1rem 0 0 0;
}
</style>

<style lang="scss">
.lookUpModal {
  .el-table {
    height: 5.5rem;
    overflow-y: auto;

    .el-table__header-wrapper {
      height: 0.5rem;
    }

    .el-table__body-wrapper {
      height: 5rem;
      overflow-y: auto;
    }
  }
}
</style>