<template>
  <div>
    <cvue-table
      :option="tableOption"
      :data="tableData"
      ref="tbl1"
      :isShowAdd="false"
      :page="page"
      :tablePage="page.currentPage"
      :pageSize="page.pageSize"
      :loading="tableLoading"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :isShowPage="page.total > 0 && tableData.length > 0"
    >
     <template slot-scope="scope" slot="time">
       <div >{{scope.row.time | formatDateTime('yyyy-MM-d HH:mm:ss')}}</div>
     </template>
      <template slot-scope="scope" slot="role">
       <div >学生</div>
     </template>
     <template slot-scope="scope" slot="temperature">
       <div style="color:red;">{{scope.row.temperature}}</div>
     </template>
      <!-- 操作按钮 -->
      <!-- <el-table-column
        slot="actionMenu"
        label="操作"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <div class="operation">
            <el-button
              type="text"
              @click="handLook(scope.row)"
              style="font-size: 0.16rem"
            >
              查看
            </el-button>
          </div>
        </template>
      </el-table-column> -->
    </cvue-table>

    <trackMap
      :id="selectedItem.userId"
     
      :modelShow="mapShow"
      
      @handelClose="mapShow = false"
    ></trackMap>
  </div>
</template>
<script>
import cvueTable from "@/components/cvue-table";
import trackMap from "../trackMap.vue";
import { refreshTime } from "@/config/config";
import { $home, $Journey, tempTodayabnormalList } from "@/api/defenceApi.js";
export default {
  components: {
    cvueTable,
    trackMap,
  },
   props: {
    date: String,
  },
   computed: {
    eduId() {
      return this.$store.state.user.countyUserInfo.organizationIdStr;
    },
  },
 
  data() {
    return {
      tableLoading: false,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 5, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50],
      },
      tableOption: {
        stripe: true,
        width: "100%",
        border: false,
        index: false,
        cloumn: [
          { prop: "time", label: "时间", align: "center" , slotStatus: true },
          { prop: "name", label: "姓名", align: "center" },
          { prop: "schoolName", label: "学校", align: "center" },
          { prop: "role", label: "角色", align: "center", slotStatus: true },
          { prop: "gradeName", label: "年级", align: "center" },
          { prop: "className", label: "班级", align: "center" },
          { prop: "temperature", label: "异常情况", align: "center", slotStatus: true },
        ],
      },
      tableData: [{a:1},{a:1},{a:1}],
      selectedItem: { userId: "" },
      mapShow: false,
    };
  },
  methods: {
    // 改变pagesize
    handleSizeChange(val) {
      this.page.pageSize = val;
    },
    // 改变页码
    handleCurrentChange(val) {
      this.page.currentPage = val;
     
        this.getList();
      
    },
    async handLook(item) {
      try {
        const data = await $Journey.getCardInfo({ UserId: item.userId });
        if (data && typeof data === "object" && data.id) {
          this.selectedItem = item;
          this.mapShow = true;
        } else {
          throw { msg: "不存在行程数据" };
        }
      } catch (err) {
        this.$message.error(`${item.name}不存在行程数据`);
      }
    },
    async getList() {
      const params = {
        districtId: this.eduId,
        
        page: this.page.currentPage,
        limit: this.page.pageSize,
      };

      let res = await tempTodayabnormalList(params)
      if(res.status == 200){
        this.page.total = res.data.total;
        this.tableData = res.data.records;
      } else {
          this.tableData = []
      }
      
     
    },
    refresh() {
      this.getList();
      // setTimeout(() => {
      //   this.refresh();
      // }, refreshTime);
    },
  },
  mounted() {
    this.refresh();
  },
 
};
</script>
<style scoped>
</style>