<template>
    <div class="main" :id="id">

    </div>
</template>
<script>
import {getGpsList,getGpsPosition} from '@/api/gpsApi'
export default {
    data () {
        return {
            id: 'container_' + Math.random(),
            map: null,
         
            childMarker: null,
            childText: null,
            childCircle: null,
            myMarker: null,
            myText: null,
           
            polygon: null,

            dangerRangeList: [],
            trackLayerList: []
        }
    },
    methods: {
       
        init () {
            return new Promise((resolve, reject) => {
                this.map = new AMap.Map(this.id, {
           
                    zoom:11,//级别
                
                    center: [119.306582, 26.08332],//中心点坐标
                   
                     mapStyle:'amap://styles/4abd51624130603c48031babfdf1c5b2'
                
                })

                this.map.on("complete", function(){
                    resolve()
                })
                this.map.on('click', (e) => { 
                    
                })

                
            })
        },
        addMarker({img, width, height, lng, lat, anchor, content}){
           
                var icon = new AMap.Icon({
                    size: new AMap.Size(width, height),    // 图标尺寸
                    image: img,  // Icon的图像
                     imageSize: new AMap.Size(width, height) 
                });
      
                // 将 Icon 实例添加到 marker 上:
                var marker = new AMap.Marker({
                  
                    position: new AMap.LngLat(lng, lat),
                     offset: new AMap.Pixel(0,0), // 设置点标记偏移量
                    anchor:anchor, // 设置锚点方位
                    icon: icon, // 添加 Icon 实例
                  
                });
   
      
                this.map.add(marker);
                return marker
            
            
         },
         addText({lng, lat, content}){
            
                var text = new AMap.Text({
                    text: content,
                    anchor:'top-center', // 设置文本标记锚点
                    draggable:true,
                    cursor:'pointer',
                    angle:0,
                    offset: new AMap.Pixel(0,20),
                    style:{
                       
                        'text-align': 'center'
                    },
                    position: [lng, lat]
                });
            
                text.setMap(this.map);
                return text
             
            
         },

         addChildMarker({lng, lat, content}){
            if (this.childCircle){
                this.map.remove(this.childCircle)
            }
            if (this.childText){
                this.map.remove(this.childText)
            }
            if (this.childMarker){
                this.map.remove(this.childMarker)
            }
            this.childCircle = this.addMarker({
                img: require('@/assets/img/map/dot.png'),
                width: 30,
                height: 30,
                lng: lng,
                lat: lat,
                anchor: 'center'
            })
           
            this.childText =  this.addText({
                content: '' + content,
                 lng: lng,
                 lat: lat 
             })

            this.childMarker = this.addMarker({
                img: require('@/assets/img/map/head.png'),
                width: 66,
                height: 71,
                lng: lng,
                lat: lat,
                anchor: 'bottom-center'
            })
        },

         getGpsList (params) {
              const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              
            getGpsList(params).then((data) => {
               loading.close()
               
               var list = data.items
               
                if(list.length == 0){
                    this.$message.error("无数据");
                    this.clearTrack()
                     return
                 }
                   var path = list.map(item => {
                     var lng = parseFloat(item.c_Longitude)
                     var lat = parseFloat(item.c_Latitude)
                     // return [lng, lat, item.createTime]
                     return {
                         lng,
                         lat,
                         createTime: item.createTime
                     }
                 })
                 if (path.length > 0) {
                   
                    this.drawTrack({path})
                 }
           }).catch((e) => {
            loading.close()
           })
        },

        drawTrack1({path}){
            
            this.addMarker({
                img: require('@/assets/img/map/start.png'),
                width: 23,
                height: 31,
                lng: path[0].lng,
                lat: path[0].lat,
                anchor: 'bottom-center'
            })

            this.addMarker({
                img: require('@/assets/img/map/end.png'),
                width: 23,
                height: 31,
                lng: path[path.length -1].lng,
                lat: path[path.length -1].lat,
                anchor: 'bottom-center'
            })

            var  graspRoad = new AMap.GraspRoad()
          
            var pathParam = path.map((obj, index) => {
                
                var tm
                if (index == 0) {
                    tm = new Date(obj.createTime).getTime() / 1000
                } else {
                    tm = new Date(obj.createTime).getTime() - new Date(path[0].createTime).getTime()
                    tm = Math.ceil(tm / 1000 / 60)
                }
                return  {
                    x: obj.lng,
                    y: obj.lat,
                    sp:10,
                    ag:0, 
                    tm:tm
                }
            })
          
            graspRoad.driving(pathParam,function(error,result){
                if(!error){
                  var path2 = [];
                  var newPath = result.data.points;
                  for(var i =0;i<newPath.length;i+=1){
                    path2.push([newPath[i].x,newPath[i].y])
                  }
                  var newLine = new AMap.Polyline({
                    path:path2,
                    strokeWeight:8,
                    strokeOpacity:0.8,
                    strokeColor:'#0091ea',
                    showDir:true
                  })
                  this.map.add(newLine)
                  this.map.setFitView([newLine])


                  var res = this.addMarker({
                        img: require('@/assets/img/map/head.png'),
                        width: 66,
                        height: 71,
                        lng: path2[0].lng,
                        lat: path2[0].lat,
                        anchor: 'bottom-center'
                    })
                    res.moveAlong(path2, 200)
                }
              })

         
        },

        drawTrack({path}){
            this.clearTrack()
           
            var start = this.addMarker({
                img: require('@/assets/img/map/start.png'),
                width: 23,
                height: 31,
                lng: path[0].lng,
                lat: path[0].lat,
                anchor: 'bottom-center'
            })
            this.trackLayerList.push(start)

            var end = this.addMarker({
                img: require('@/assets/img/map/end.png'),
                width: 23,
                height: 31,
                lng: path[path.length -1].lng,
                lat: path[path.length -1].lat,
                anchor: 'bottom-center'
            })
            this.trackLayerList.push(end)


             
            
            path = path.map(obj => {
                return [obj.lng, obj.lat]
            })

            
                
           
            var polygon = new AMap.Polyline({
              
            


                path:path,
                strokeWeight:8,
                strokeOpacity:0.8,
                strokeColor:'#0091ea',
                showDir:true
            });

            this.map.add(polygon)
            this.map.setFitView([polygon])
            this.trackLayerList.push(polygon)
            
            var res = this.addMarker({
                img: require('@/assets/img/map/head.png'),
                width: 66,
                height: 71,
                lng: path[0].lng,
                lat: path[0].lat,
                anchor: 'bottom-center'
            })
            res.moveAlong(path, 200)
            this.trackLayerList.push(res)           
        },

        getLastGPS(studentId){
           
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              })
            getGpsPosition(studentId).then(async res => {
                loading.close()
                 if (!res) {
                    this.$message.error('暂无数据')
                    return
                 }

                  var lng = parseFloat(res.c_Longitude + '')
                  var lat = parseFloat(res.c_Latitude + '')
                  var address = res.c_Address
                  var positionType = res.positionType
                  var positionTypeStr = ''
                  if(positionType == 1){
                    positionTypeStr = 'GPS定位'
                  }else if(positionType == 2){
                    positionTypeStr = 'WIFI定位'
                  }else if (positionType == 3) {
                    positionTypeStr = '基站定位(存在误差)'
                  }
                  var content = res.createTime.replace('T', ' ') + " <br>" + address + '<br>' + '定位类型：' + positionTypeStr
                
                  this.addChildMarker({lng, lat, content})
                 
                 
                  this.map.setFitView(this.childMarker)

               
                 
                
            }).catch(() => {
                loading.close()
            })

           
          },

        drawDangerRange(pathList){
           
            this.clearDangerRange()


            for(var path of pathList){
                var polygon = new AMap.Polygon({
                    map: this.map,
                    path: path,//设置多边形边界路径
                    strokeColor: "#d6333f", //线颜色
                    strokeOpacity: 0.2, //线透明度
                    strokeWeight: 3,    //线宽
                    fillColor: "#d6333f", //填充色
                    fillOpacity: 0.4//填充透明度
                });

                this.dangerRangeList.push(polygon)
            }
            

            this.map.setFitView();
        },

        clearDangerRange(){
            for(var item of this.dangerRangeList){
                this.map.remove(item)
            }
            this.dangerRangeList = []
        },

        clearTrack(){
           
             for(var item of this.trackLayerList){
                this.map.remove(item)
            }
            this.trackLayerList = []
        }
        
         
    },
    async mounted() {
       
    }
}
</script>
<style scoped>
.main{
    width: 100%; height: 100%;
}
</style>
<style>
.amap-logo{
    display: none !important;
}
</style>
