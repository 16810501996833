<template>
  <div class="defenceModal">
    <modal
      :modelSize="'max'"
      :modelShow="modelShow"
      @open="open"
      @handelClose="handelClose"
    >
      <template slot="body">
        <div class="modal-container">
          <div class="modal-title">健康监测</div>

          <div class="tab-list">
            <div
              @click="tabIndex = 0"
              class="tab-item"
              :class="{ active: tabIndex === 0 }"
            >
              首页
            </div>
             <div
              @click="tabIndex = 1"
              class="tab-item"
              :class="{ active: tabIndex === 1 }"
            >
              学生证测温数据
            </div>
            <div
              @click="tabIndex = 2"
              class="tab-item"
              :class="{ active: tabIndex === 2 }"
            >
              上报数据
            </div>
            <div
              @click="tabIndex = 3"
              class="tab-item"
              :class="{ active: tabIndex === 3 }"
            >
              闸机数据
            </div>
            <!-- <div
              @click="tabIndex = 4"
              class="tab-item"
              :class="{ active: tabIndex === 4 }"
            >
              行程卡数据
            </div> -->
          </div>
          <!-- 首页-->
          <div class="modal-body">
            <index
              v-show="tabIndex === 0"
              :modalShow="modalShow && tabIndex === 0"
            ></index>
            <!-- 学生证测温数据-->
            <cardData
               v-show="tabIndex === 1"
              :modalShow="modalShow && tabIndex === 1"
            ></cardData>
            <!-- 上报数据-->
            <upData
              v-show="tabIndex === 2"
              :modalShow="modalShow && tabIndex === 2"
            ></upData>
            <!--闸机数据 -->
            <hardData
              v-show="tabIndex === 3"
              :modalShow="modalShow && tabIndex === 3"
            ></hardData>
            <!--行程卡数据 -->
            <tripData
              v-show="tabIndex === 4"
              :modalShow="modalShow && tabIndex === 4"
            ></tripData>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/modal";
import index from "./defence/index/index.vue";
import upData from "./defence/up/upData.vue";
import hardData from "./defence/hard/hardData.vue";
import tripData from "./defence/trip/tripData.vue";
import cardData from './defence/card/cardData.vue'
export default {
  name: "dormModal",
  components: {
    Modal,
    index,
    upData,
    hardData,
    tripData,
    cardData
  },
  props: {
    modelShow: Boolean,
    modelSize: String,
  },
  data() {
    return {
      tabIndex: 0,
      modalShow: false,
    };
  },
  computed: {
    countyUserInfo() {
      return this.$store.state.user.countyUserInfo;
    },
  },
  watch: {
    tabIndex: function () {},
  },
  mounted() {},
  methods: {
    open() {
      this.modalShow = true;
      this.tabIndex = this.$store.state.county.defenceModalTabIndex;
    },
    handelClose() {
      this.modalShow = false;
      this.$emit("handelClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  box-sizing: border-box;
  padding: 0.3rem 1.14rem;
  .modal-title {
    text-align: center;
    color: #fff;
    font-size: 0.28rem;
    font-family: Microsoft YaHei;
    padding: 0 0 0.2rem 0;
    
  }
  .modal-list {
    h4 {
      font-size: 0.16rem;
      color: #00a2ff;
      box-sizing: border-box;
      font-weight: 600;
    }
    .viewChart {
      overflow: auto;
    }
  }
}
.tab-list {
  border-bottom: 2px solid #152952;
}
.tab-item {
  background: #1d325b;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.2rem;
  height: 0.36rem;
  line-height: 0.36rem;
  text-align: center;
  font-size: 0.16rem;
  color: #adb9d2;
  border-radius: 0.08rem 0.08rem 0 0;
  cursor: pointer;
  margin: 0 0.05rem 0 0;
}
.tab-item.active {
  background: #2482d9;
  color: #fff;
}
.modal-body {
  height: calc(7.8rem - 0.2rem - 0.6rem - 0.36rem - 0.38rem);
  overflow-x: hidden;
  overflow-y: scroll;
}

.modal-body::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>
<style>
.dormModal .modal-list .el-table,
.dormModal .modal-list .el-table tr,
.dormModal .modal-list .el-table td,
.dormModal .modal-list .el-table th {
  background-color: transparent !important;
  border: none;
  text-align: center;
}
.dormModal .modal-list .el-table {
  margin-top: 0.35rem;
}
.dormModal .modal-list .el-table::before {
  background-color: transparent !important;
}
.dormModal .modal-list .el-table th {
  font-size: 0.16rem;
  color: #fff;
  height: 0.4rem;
}
.dormModal .modal-list .el-table td {
  padding: 0.2rem 0;
  font-size: 0.14rem;
  color: #b8c8e9;
}
.dormModal
  .modal-list
  .el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0.1rem;
  height: 0.1rem;
}
.dormModal
  .modal-list
  .el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  background: #395283;
}
.dormModal
  .modal-list
  .el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: #152952;
  border-radius: 0.1rem;
}
</style>