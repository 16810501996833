import axios from './axios'
import qs from 'qs'
import {apiUrl, appUrl} from '@/config/config'

export const getSchoolList = (id) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/relation/edu/tree/' + id,
            method: 'get'
        }).then(res => {
            resolve(res)
        })
    })
}

export const getSchoolDetail = (params) => {
    return new Promise((resolve,reject) => {
        axios({
            url: apiUrl + '/district-command-center/dataStatistics/schoolInfo',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        })
    })
}


// 获取年级
export const getGrade = (params) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${appUrl}/bdc/api/app/school/${params.id}/grades`,
            method: 'get',
            params
        }).then(res => {
          resolve(res)
        })
    })
}

// 获取年级
export const getClass = (params) => {
    return new Promise((resolve, reject) => {
        axios({
            url: `${appUrl}/bdc/api/app/school/${params.id}/classes`,
            method: 'get',
            params
        }).then(res => {
          resolve(res)
        })
    })
}