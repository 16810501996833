<template>
  <div class="con-cah">
    <chart :options="pie" :init-options="initOptions" ref="pie" autoresize />
  </div>
</template>
<script>
import ECharts from "@/components/echarts/ECharts.vue";

export default {
  components: {
    chart: ECharts
  },
  props: {
    //[{name:"推荐量",color:"#34243",value:123},
    //{name:"阅读量",color:"#34242",value:123},
    //{name:"转发量",color:"#34243",value:123},
    //{name:"收藏量",color:"#34243",value:123}
    //]
    data: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: ""
    }
  },
  watch: {
    data(newVal, oldVue) {
      this.init();
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      pie: {
        title: {
          text: this.title,
          x: "center",
          textStyle: {
            //文本颜色
            color: "#71e3fe",
            fontSize: 16
          },
          top: 10
          // axisTick: {
          //   show: false
          // }
        },

        tooltip: {//鼠标悬停提示
          show: true,
          trigger: "item",
          formatter: "{b}<br/>{c} ({d}%)"
        },
        // legend: {
        //     orient: 'vertical',
        //     left: 'left',
        //     data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
        // },
        series: [
          {
            name: this.title,
            type: "pie",
            radius: "90%",
            // center: ["50%", "60%"],
            label: {
              normal: {
                show: true, //显示数据
                color: "#fff",
                position: "inner", //标签的位置
                textStyle: {
                  fontWeight: 300,
                  fontSize: 12 //文字的字体大小
                },
                formatter: "{d}%"
              }
            },
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
           
          }
        ]
      }
    };
  },
  methods: {
    init() {
      var list = [];
      for (var item of this.data) {
        list.push({
          value: item.value,
          name: item.name,
          radius: item.radius,
          itemStyle: {
            normal: {
              color: item.color
            }
          }
        });
      }
      this.pie.series[0].data = list;
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style  scoped>
.con-cah {
  width: 100%;
  height: 100%;
}
</style>