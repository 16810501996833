<template>
  <div class="main-p" :style="{ background: color }">
    <div class="title" :style="{ color: titleColor }">大数据行程卡</div>
    <div class="desc" :style="{ color: bottomColor }">疫情防控，人人有责</div>
    <div v-if="type != 3" class="card-top" :style="{ color: color }">
      {{ name }}的行程卡
    </div>
    <div class="content">
      <div v-if="updataDate" class="time">
        更新于 {{ updataDate | formatDateTime("yyyy-MM-dd hh:mm:ss") }}
      </div>
      <img
        v-if="type == 1"
        class="green-img"
        src="@/assets/img/map/green.png"
        alt=""
      />
      <img
        v-if="type == 2"
        class="green-img"
        src="@/assets/img/map/yellow.png"
        alt=""
      />
      <img
        v-if="type == 3"
        class="no-data"
        src="@/assets/img/map/no-data.png"
        alt=""
      />
      <div v-if="type != 3" class="line"></div>
      <div v-if="type != 3" class="tujin">
        <label class="text-g">您于前14天内到达或途径：</label>
        <span v-html="path"></span>
        <!-- {{ path.join("、") }} -->
      </div>
      <div v-else class="no-data-text">
        <div class="sorry">抱歉，没有找到您的行程数据</div>
        <div class="no-use">设备未使用，或停留时间过短</div>
      </div>
    </div>
    <div class="bottom" :style="{ color: bottomColor }">
      <div>行程卡包含您在前14天内到滞留4小时以上的区县</div>
      <div>色卡仅对访地作提醒，不关联健康状况</div>
    </div>
  </div>
</template>
<script>
import { $Journey } from "@/api/defenceApi.js";
export default {
  props: {
    id: String,
    openCount: Number,
    isWxUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: 3, //  1:green  2:yellow  3:noData
      updataDate: new Date(),
      name: "",
      path: "",
    };
  },
  watch: {
    openCount() {
      this.id && this.getCardInfo();
    },
  },
  methods: {
    async getCardInfo() {
      // console.log("获取行程卡详情", this.id);
      let data = null;
      if (this.isWxUser === true) {
        data = await $Journey.getWxUserCardInfo({ UserId: this.id });
      } else {
        data = await $Journey.getCardInfo({ UserId: this.id });
      }

      // console.log("获取行程卡详情", data);
      if (data) {
        const TYPE_SELECT = {
          "-1": 3, // 无数据
          0: 1, // 正常
          1: 2, // 中风险
          2: 2, // 高风险
        };
        this.type = TYPE_SELECT[data.riskAreaType] || 3;
        let addressPath = new String();
        let pathLength = data.userLastAdress.length;
        data.userLastAdress.forEach((e, i) => {
          let item = {
            name: e,
            danger: false,
          };
          data.remark.forEach((r) => {
            e === r && (item.danger = true);
          });
          addressPath += `<span ${
            item.danger ? 'style="color:#ff0000;"' : ""
          }>${item.name}</span>${i < pathLength - 1 ? "、" : ""}`;
        });
        this.path = addressPath;
        this.name = data.studentName;
        this.updataDate = data.lastModificationTime;
      } else {
        this.type = 3;
      }
    },
  },
  computed: {
    color() {
      var val = "";
      switch (this.type) {
        case 1:
          val = "#2BAD6A";
          break;
        case 2:
          val = "#F0AC37";
          break;
        case 3:
          val = "#fff";
          break;
      }
      return val;
    },
    titleColor() {
      var val = "";
      switch (this.type) {
        case 1:
          val = "#fff";
          break;
        case 2:
          val = "#fff";
          break;
        case 3:
          val = "#000";
          break;
      }
      return val;
    },
    bottomColor() {
      var val = "";
      switch (this.type) {
        case 1:
          val = "#fff";
          break;
        case 2:
          val = "#fff";
          break;
        case 3:
          val = "#999999";
          break;
      }
      return val;
    },
  },
};
</script>
<style scoped>
.main-p {
  width: 3.42rem;
  background: #2bad6a;
  padding: 0.2rem;
  box-sizing: border-box;
  border-radius: 0.05rem;
}
.title {
  text-align: center;
  font-size: 0.24rem;
  color: #fff;
  padding: 0 0 0.08rem 0;
}
.desc {
  text-align: center;
  font-size: 0.12rem;
  color: #fff;
  padding: 0 0 0.2rem 0;
}
.card-top {
  width: 3.06rem;
  height: 0.36rem;
  line-height: 0.36rem;
  background: url(../../../assets/img/map/card-top.png);
  text-align: center;
  font-size: 0.14rem;
  color: #2bad6a;
}
.content {
  width: 3.06rem;
  box-sizing: border-box;
  padding: 0.16rem;
  background: #fff;
}
.time {
  color: #999999;
  font-size: 0.12rem;
  text-align: center;
}
.green-img {
  width: 0.92rem;
  height: 0.86rem;
  margin: 0.32rem auto;
  display: block;
}
.no-data {
  width: 2.32rem;
  height: 1.4rem;
  display: block;
  margin: 0 auto 0.4rem auto;
}
.line {
  border-top: 1px solid #eaeaea;
}
.tujin {
  font-size: 0.16rem;
  padding: 0.15rem 0 0 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.text-g {
  color: #999999;
}
.bottom {
  font-size: 0.12rem;
  color: #fff;
  text-align: center;
  padding: 0.2rem 0 0 0;
}
.sorry {
  font-size: 0.16rem;
  color: #999999;
  text-align: center;
}
.no-use {
  font-size: 0.14rem;
  text-align: center;
}
</style>