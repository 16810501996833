<template>
  <div class="children-table">
    <!-- <el-button
      type="primary"
      v-show="modelShow && page.total > 0 && tableData.length > 0"
      class="export-btn"
      @click="exportExcel"
    >
      按检索结果导出
    </el-button> -->
    <cvue-table
      :option="tableOption"
      :data="tableData"
      ref="children"
      :isShowAdd="false"
      :page="page"
      :tablePage="page.currentPage"
      :pageSize="page.pageSize"
      :loading="tableLoading"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :isShowPage="page.total > 0 && tableData.length > 0"
    >
      <!-- 操作按钮 -->
      <el-table-column
        slot="actionMenu"
        label="操作"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <div class="operation">
            <el-button
              type="text"
              @click="handLook(scope.row)"
              style="font-size: 0.16rem"
            >
              查看
            </el-button>
          </div>
        </template>
      </el-table-column>
    </cvue-table>
  </div>
</template>

<script>
import cvueTable from "@/components/cvue-table";
import { $itineraryCard, $Journey, $home } from "@/api/defenceApi.js";
import util from "@/util/timeFormat.js";
export default {
  data() {
    return {
      tableLoading: false,
      tableOption: {
        stripe: true,
        width: "100%",
        border: false,
        index: false,
        cloumn: [
          { prop: "date", label: "时间", align: "center" },
          { prop: "studentName", label: "姓名", align: "center" },
          { prop: "schoolName", label: "学校", align: "center" },
          { prop: "gradeName", label: "年级", align: "center" },
          { prop: "className", label: "班级", align: "center" },
          { prop: "deviceNo", label: "学生证设备号", align: "center" },
          { prop: "result", label: "监测结果", align: "center" },
        ],
      },
      tableData: [],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 5, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50],
      },
    };
  },
  mounted(){
    this.init()
  },
  methods: {
    init() {
      
      console.log("children-table show");
      this.page.currentPage = 1;
      this.page.total = 0;
      this.tableData = [];
      this.getTableData();
    },
    async getTableData() {
      try {
        this.tableLoading = true;
        const { status, data } = await $home.getIneraryCardList({
        
          eduId: this.countyUserInfo.organizationIdStr,
          page: this.page.currentPage,
          limit: this.page.pageSize,
          date: this.date,
        });
        
        // console.log("行程卡详情-孩子", status, data);
        if (
          (status === 0 || status === 200) &&
          data &&
          typeof data === "object"
        ) {
          data.list.forEach((e) => {
            e.result = e.result.replace(/null/g, "");
            e.result = e.result.replace(/#/g, "");
          });
          this.tableData = data.list || [];
          this.page.total = data.totalCount || 0;
        }
        this.tableLoading = false;
      } catch {
        this.tableLoading = false;
      }
    },
    /**导出 */
    async exportExcel() {
      if (this.tableData.length === 0) {
        this.$message.error("暂无导出数据");
        return;
      }
      const res = await $itineraryCard.exportDetail({
      
        id: this.id,
        date: this.date,
        page: this.page.currentPage,
        limit: this.page.pageSize,
      });
      // console.log(`健康监测-上报学生${this.schoolName}详情数据列表导出`, res);
      const down = document.createElement("a");
      const blob = new Blob([res], { type: "application/vnd.ms-excel" });
      down.href = URL.createObjectURL(blob);
      down.download = `健康监测-${this.schoolName}行程卡数据-${util.formatting(
        this.date,
        "YYYY年MM月DD日"
      )}.xls`;
      down.target = "_blank";
      // console.log(down);
      down.click();
    },
    /**查看行程卡 */
    async handLook(item) {
      try {
        const data = await $Journey.getCardInfo({ UserId: item.userId });
        if (data && typeof data === "object" && data.id) {
          this.$emit("lookcard", item);
        } else {
          throw { msg: "不存在行程数据" };
        }
      } catch (err) {
        this.$message.error(`${item.name}不存在行程数据`);
      }
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
    },
    handleSizeChange() {},
  },
  watch: {
    modelShow(e) {
     
    },
    "page.currentPage"() {
      this.getTableData();
    },
  },
  computed: {
     countyUserInfo() {
      return this.$store.state.user.countyUserInfo;
    },
  },
  props: {
    modelShow: Boolean,
    date: String,
    id: String,
    schoolName: String,
  },
  components: { cvueTable },
};
</script>

<style lang="scss" scoped>
.children-table {
  position: relative;

  .export-btn {
    position: absolute;
    top: -0.6rem;
    right: 0.2rem;
  }
}
</style>