import { render, staticRenderFns } from "./patriarchTable.vue?vue&type=template&id=71a81aa2&scoped=true&"
import script from "./patriarchTable.vue?vue&type=script&lang=js&"
export * from "./patriarchTable.vue?vue&type=script&lang=js&"
import style0 from "./patriarchTable.vue?vue&type=style&index=0&id=71a81aa2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a81aa2",
  null
  
)

export default component.exports