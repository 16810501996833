<template>
  <div class="children-module">
    <modal
      :modelSize="'max'"
      :modelShow="modelShow"
      :zIndex="400"
      @open="open"
      @handelClose="handelClose()"
    >
      <template slot="body">
        <div class="children-module content">
          <div class="modal-title">关联孩子信息</div>
          <cvue-table
            :option="tableOption"
            :data="tableData"
            ref="patriarch"
            :isShowAdd="false"
            :page="page"
            :tablePage="page.currentPage"
            :pageSize="page.pageSize"
            :loading="tableLoading"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :isShowPage="false"
          >
          </cvue-table>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/modal";
import cvueTable from "@/components/cvue-table";
import { $itineraryCard } from "@/api/defenceApi";

export default {
  data() {
    return {
      tableLoading: false,
      tableOption: {
        stripe: true,
        width: "100%",
        border: false,
        index: false,
        cloumn: [
          { prop: "name", label: "姓名", align: "center" },
          { prop: "gradeName", label: "年级	", align: "center" },
          { prop: "className", label: "班级", align: "center" },
        ],
      },
      tableData: [],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50],
      },
    };
  },
  methods: {
    async getTableData() {
      const res = await $itineraryCard.getChildrensByPatriarch({
        userId: this.userId,
        // schoolId: this.schoolId,
      });
      this.tableData = res;
    },
    init() {
      // this.page.currentPage !== 1 && (this.page.currentPage = 1);
      // this.page.total = 0;
      this.tableData = [];
      this.getTableData();
    },
    open() {
      this.init();
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
    },
    handleSizeChange() {},
    handelClose() {
      this.$emit("handelClose");
    },
  },
  watch: {
    "page.currentPage"() {
      this.getTableData();
    },
  },
  computed: {},
  components: {
    modal: Modal,
    "cvue-table": cvueTable,
  },
  props: {
    userId: String,
    schoolId: String,
    modelShow: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0.34rem 0.64rem 0;
}
.modal-title {
  color: #00a2ff;
  font-size: 0.16rem;
  line-height: 0.48rem;
  height: 0.48rem;
}
</style>

<style lang="scss">
.children-module {
  .el-table {
    height: 5.5rem;
    overflow-y: auto;

    .el-table__header-wrapper {
      height: 0.5rem;
    }

    .el-table__body-wrapper {
      height: 5rem;
      overflow-y: auto;
    }
  }
}
</style>