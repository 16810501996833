<template>
  <div class="lookCardData">
    <modal
      :modelSize="'max'"
      :modelShow="modelShow"
      :zIndex="300"
      @open="open"
      @handelClose="$emit('handelClose')"
    >
      <template slot="body">
        <div class="content lookCardData">
          <div class="export-l">
            <div class="tab-list">
              <div
                class="tab-item"
                :class="{ active: tab === 1 }"
                @click="tab = 1"
              >
                学生
              </div>
              <div
                class="tab-item"
                :class="{ active: tab === 2 }"
                @click="tab = 2"
              >
                家长
              </div>
            </div>
          </div>
          <children-table
            v-show="tab === 1"
            :id="id"
            :modelShow="modelShow && tab === 1"
            :schoolName="schoolName"
            :date="date"
            @lookcard="lookPathCard"
          ></children-table>
          <patriarch-table
            v-show="tab === 2"
            :id="id"
            :modelShow="modelShow && tab === 2"
            :schoolName="schoolName"
            :date="date"
            @lookcard="lookPathCard"
          ></patriarch-table>
        </div>
      </template>
    </modal>

    <trackMap
      :modelShow="mapShow"
      :id="choseItem.userId"
     
      :isWxUser="choseItem.isWxUser === true"
     
      @handelClose="mapShow = false"
    ></trackMap>
  </div>
</template>

<script>
import Modal from "@/components/modal/modal";
import trackMap from "../trackMap.vue";
import childrenTable from "./childrenTable.vue";
import patriarchTable from "./patriarchTable.vue";
export default {
  name: "cardlook",
  data() {
    return {
      tableLoading: false,
      tab: 1,
      choseItem: { userId: "", isWxUser: false },
      mapShow: false,
    };
  },
  computed: {
    countyUserInfo() {
      return this.$store.state.user.countyUserInfo;
    },
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.tab = 1;
      });
    },
    lookPathCard(item) {
      
      item.isWxUser = item.isWxUser === true;
      this.choseItem = item;
      this.mapShow = true;
    },
  },
  components: {
    Modal,
    trackMap,
    "children-table": childrenTable,
    "patriarch-table": patriarchTable,
  },
  props: {
    modelShow: Boolean,
    modelSize: String,
    id: String,
    date: String,
    schoolName: String,
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0.34rem 0.64rem 0;
}
.export-l {
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .tab-list {
    border-bottom: 2px solid #152952;
    width: 100%;

    .tab-item {
      background: #1d325b;
      display: inline-block;
      vertical-align: middle;
      width: 0.96rem;
      height: 0.36rem;
      line-height: 0.36rem;
      text-align: center;
      font-size: 0.16rem;
      color: #adb9d2;
      border-radius: 0.08rem 0.08rem 0 0;
      cursor: pointer;
      margin: 0 0.05rem 0 0;

      &.active {
        background: #2482d9;
        color: #fff;
      }
    }
  }

  .export-btn {
    position: absolute;
    right: 0.2rem;
    bottom: 0.3rem;
  }
}
</style>

<style lang="scss">
.lookCardData {
  .el-table {
    height: 5.5rem;
    overflow-y: auto;

    .el-table__header-wrapper {
      height: 0.5rem;
    }

    .el-table__body-wrapper {
      height: 5rem;
      overflow-y: auto;
    }
  }
}
</style>