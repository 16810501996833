<template>
     <div class="option-bar" style="display: flex; align-items: center;" >
        <!-- <div style="margin-right: 10px;" >
          <el-select v-model="stage" value-key="id" @change="stageChange" placeholder="学段">
            <el-option
              v-for="(val, index) in stageList"
              :key="index"
              :label="val.name"
              :value="val">
            </el-option>
          </el-select>
        </div> -->

        <div style="margin-right: 10px;" >
          <div class="form-lab">年级：</div>
          <el-select v-model="grade" value-key="id" @change="gradeChange" placeholder="年级">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(val, index) in gradeList"
              :key="val.id"
              :label="val.name"
              :value="val">
            </el-option>
          </el-select>
        </div>

        <div style="margin-right: 20px;" >
           <div class="form-lab">班级：</div>
          <el-select v-model="clas" value-key="id" placeholder="班级">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(val, index) in classList"
              :key="val.id"
              :label="val.name"
              :value="val">
            </el-option>
          </el-select>
        </div>
        <template v-if="showShowMode">
          <el-radio v-model="showMode" :label="1">显示全部</el-radio>
          <el-radio v-model="showMode" :label="2">只显示异常</el-radio>
        </template>
        <div style="margin-right: 20px;" v-if="showRiskCardState">
           <div class="form-lab">行程卡状态：</div>
          <el-select v-model="riskCardState" value-key="id" >
             <el-option label="全部" value=""></el-option>
                <el-option label="异常" value="1"></el-option>
                <el-option label="正常" value="0"></el-option>
                <el-option label="无数据" value="-1"></el-option>
          </el-select>
        </div>

        
        <el-button type="primary" @click="queryClick">检索</el-button>
        <el-button type="primary" @click="excelClick">按检索导出结果</el-button>
       
      </div>
</template>
<script>

import {getGrade, getClass} from '@/api/schoolApi'
export default {
  props: {
        showShowMode: {
            type: Boolean,
            default: true
        },
        showRiskCardState: {
             type: Boolean,
            default: true
        },
        schoolId: {
            type: String,
            default: 'aaa'
        }
        
    },

    computed: {
    
    },

    data(){
        return {
            stage: '',
            stageList: [],
            grade: '',
            gradeList: [],
            clas: '',
            classList: [],
            showMode: 1,  // 显示全部 || 显示异常
            riskCardState: ''
        }
    },
    methods: {
         // 年级切换触发事件
      async gradeChange () {
        let res = await getClass({
                    gradeId: this.grade.id,
                    id: this.schoolId
                  })
        this.classList = res.items
        this.clas = ''
      },
      // 学段切换触发事件
      stageChange () {
        this.gradeList = this.stage.grades
        // this.gradeList.length > 0 ? this.grade = this.stage.grades[0] : this.grade = ''
        this.grade = ''
        this.clas = ''
      },
        queryClick(){
           this.change()

            this.$emit('queryClick')
        },
        excelClick(){
            this.$emit('excelClick')
        },
        change(){
           this.$emit('change', {
              clas: this.clas,
              grade: this.grade,
              showMode: this.showMode,
              riskCardState: this.riskCardState
          })
        }
    },
    async mounted(){
       let res = await getGrade({
         id: this.schoolId
       })
       
       this.gradeList = res.items
       this.queryClick()
      
    }
}
</script>
<style scoped>

  .screen-button {
    width: 56px;
    height: 30px;
    border-radius: 5px;
    background: #2482d9;
    color: #fff;
    font-size: 14px;
    border: 0;
    margin-right: 60px;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .exportImg {
    width: 16px;
    height: 14px;
    margin-right: 10px;
    cursor: pointer;
  }
  .exportText{
    color: #00E9FE;  margin-right: 20px; cursor: pointer;
  }
  .form-lab{
    display: inline-block; vertical-align: middle; color: #fff;
  }
</style>
<style >

.option-bar .el-input--small .el-input__inner {
  width: 90px!important;
  background: #152021!important;
  border: 1px solid #2482d9!important;
  color: #fff!important;
}


.option-bar .el-radio__input.is-checked+.el-radio__label {
  color: #fff!important;
}

.option-bar .el-radio__label {
  color: #fff!important;
}

/* .el-radio__input.is-checked .el-radio__inner {
  border-color: #fff!important;
  background: #00555D!important;
}

.el-radio {
  margin-right: 15px!important;
} */

</style>