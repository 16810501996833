<template>
  <div>
     <div class="tab-list">
       <div
                class="tab-item"
                :class="{ active: tab === 1 }"
                @click="tab = 1"
              >
                学生
              </div>
              <div
                class="tab-item"
                :class="{ active: tab === 2 }"
                @click="tab = 2"
              >
                家长
          </div>
     </div>

     <children-table
            v-if="tab === 1"
          
           
            :date="date"
            @lookcard="lookPathCard"
          ></children-table>
          <patriarch-table
            v-if="tab === 2"
          
           
            :date="date"
            @lookcard="lookPathCard"
          ></patriarch-table>
  

    <trackMap
      :id="choseItem.userId"
      :initDate="choseItem.date"
      :modelShow="mapShow"
      :isWxUser="choseItem.isWxUser === true"
      @handelClose="mapShow = false"
    ></trackMap>
  </div>
</template>
<script>
import cvueTable from "@/components/cvue-table";
import trackMap from "../trackMap.vue";
import childrenTable from "./childrenTable.vue";
import patriarchTable from "./parentTable.vue";
import { refreshTime } from "@/config/config";
import { $home, $Journey } from "@/api/defenceApi.js";
export default {
  components: {
    cvueTable,
    trackMap,
    childrenTable,
    patriarchTable
  },
  data() {
    return {
       
      mapShow: false,

      tab: 1,
      choseItem: {},
    };
  },
  methods: {
    lookPathCard(item) {
    
      this.choseItem = item;
      this.mapShow = true;
    },

   
  },
  mounted() {
   
  },
 
  props: {
    date: String,
  },
};
</script>
<style scoped  lang="scss">
.tab-list {
    border-bottom: 2px solid #152952;
    width: 100%;
    margin: 0.2rem 0 0 0;
    .tab-item {
      background: #1d325b;
      display: inline-block;
      vertical-align: middle;
      width: 0.96rem;
      height: 0.36rem;
      line-height: 0.36rem;
      text-align: center;
      font-size: 0.16rem;
      color: #adb9d2;
      border-radius: 0.08rem 0.08rem 0 0;
      cursor: pointer;
      margin: 0 0.05rem 0 0;

      &.active {
        background: #2482d9;
        color: #fff;
      }
    }
  }
</style>