<template>
  <div class="lookUpModal">
    <modal
      :modelSize="'max'"
      :modelShow="modelShow"
      :zIndex="300"
      @open="open"
      @handelClose="$emit('handelClose')"
    >
      <template slot="body">
        <div class="content lookUpModal">
          <div class="export-l" v-if="page.total > 0 && tableData.length > 0">
            <el-button type="primary" @click="exportExcel">
              按检索结果导出
            </el-button>
          </div>
          <cvue-table
            :option="tableOption"
            :data="tableData"
            ref="tbl1"
            :isShowAdd="false"
            :page="page"
            :tablePage="page.currentPage"
            :pageSize="page.pageSize"
            :loading="tableLoading"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :isShowPage="page.total > 0"
          >
              <template slot-scope="scope" slot="temperature">
                <div :style="{color:scope.row.temperature > 37 || scope.row.temperature < 36 ? 'red' : ''}">{{scope.row.temperature}}℃</div>
              </template>
          </cvue-table>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { $reported } from "@/api/defenceApi.js";
import Modal from "@/components/modal/modal";
import util from "@/util/timeFormat.js";
import cvueTable from "@/components/cvue-table";
export default {
  name: "lookUp",
  data() {
    return {
      tableLoading: false,
      page: {
        total: 0, // 总条目
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50],
      },

      tableOption: {
        stripe: true,
        width: "100%",
        border: false,
        index: false,
        cloumn: [
          { prop: "date", label: "时间", align: "center" },
          { prop: "name", label: "姓名", align: "center" },
          { prop: "gradeName", label: "年级", align: "center" },
          { prop: "className", label: "班级", align: "center" },
          { prop: "schoolName", label: "学校", align: "center" },
          { prop: "temperature", label: "体温", align: "center", slotStatus: true},
          { prop: "source", label: "数据来源", align: "center" },
        ],
      },
      tableData: [],
    };
  },
  mounted() {},
  methods: {
    // 改变pagesize
    handleSizeChange(val) {},
    // 改变页码
    handleCurrentChange(val) {
      this.page.currentPage = val;
       this.getReportDetail();
    },
    open() {
      this.$nextTick(() => {
        this.page.currentPage = 1;
        this.page.total = 0;
        this.tableData = [];
        this.getReportDetail();
      });
    },
    async getReportDetail() {
      try {
        this.tableLoading = true;
        const { status, data } = await $reported.getDetail({
          id: this.id,
          page: this.page.currentPage,
          limit: this.page.pageSize,
          date: this.date,
        });
        // console.log("上报详情", status, data);
        if (
          (status === 0 || status === 200) &&
          data &&
          typeof data === "object"
        ) {
          data.records.forEach((e) => {
            e.schoolName = this.schoolName;
          });
          this.tableData = data.records;
          this.page.total = data.total;
        }
        this.tableLoading = false;
      } catch {
        this.tableLoading = false;
      }
    },
    async exportExcel() {
      if (this.tableData.length === 0) {
        this.$message.error("暂无导出数据");
        return;
      }
      const res = await $reported.exportDetail({
        id: this.id,
        date: this.date,
        schoolName: this.schoolName,
      });
      // console.log(`健康监测-上报${this.schoolName}详情数据列表导出`, res);
      const down = document.createElement("a");
      const blob = new Blob([res], { type: "application/vnd.ms-excel" });
      down.href = URL.createObjectURL(blob);
      down.download = `健康监测-${this.schoolName}上报数据-${util.formatting(
        this.date,
        "YYYY年MM月DD日"
      )}.xls`;
      down.target = "_blank";
      // console.log(down);
      down.click();
    },
  },
  
  computed: {
    countyUserInfo() {
      return this.$store.state.user.countyUserInfo;
    },
  },
  components: {
    Modal,
    cvueTable,
  },
  props: {
    modelShow: Boolean,
    modelSize: String,
    id: String,
    date: String,
    schoolName: String,
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0.34rem 0.64rem 0;
}
.export-l {
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>

<style lang="scss">
.lookUpModal {
  .el-table {
    height: 5.5rem;
    overflow-y: auto;

    .el-table__header-wrapper {
      height: 0.5rem;
    }

    .el-table__body-wrapper {
      height: 5rem;
      overflow-y: auto;
    }
  }
}
</style>