import axios from './axios'
import { apiUrl, cardUrl, appUrl } from '@/config/config'
import util from "@/util/timeFormat.js";


/**大屏-历史监测数据 */
export const getHisData = (eduId) => {
    return new Promise((resolve, reject) => {
        const path = "/district-command-center/epidemicPrevention/getHisData"
        axios({
            method: "get",
            url: `${apiUrl}${path}?eduId=${eduId}`,
            data: {},
            headers: {
                "content-type": "application/x-www-form-urlencoded"
            }
        }).then(res => {
            resolve(res)
        })
    })
}

/**查看历史异常学生卡上报体温数 */
export const tempHistoryCount = (params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `${apiUrl}/temperature-monitor-center/district/studentCardTemperature/historyCount`,
            params,
           
        }).then(res => {
            resolve(res)
        })
    })
}

/**查看当日异常学生卡上报体温数 */
export const tempTodayabnormalCount = (params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `${apiUrl}/temperature-monitor-center/district/studentCardTemperature/today/abnormalCount`,
            params,
           
        }).then(res => {
            resolve(res)
        })
    })
}


/**查询当日异常学生卡上报体温数据 */
export const tempTodayabnormalList = (params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `${apiUrl}/temperature-monitor-center/district/studentCardTemperature/today/abnormalList`,
            params,
           
        }).then(res => {
            resolve(res)
        })
    })
}


/**学生证测温，学校列表 */
export const tempSchoolList = (params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: `${apiUrl}/temperature-monitor-center/district/studentCardTemperature/list/bySchool`,
            params,
           
        }).then(res => {
            resolve(res)
        })
    })
}



//学生证测温，学校列表  ---导出
export const exportTempSchoolList = (params) => {
    var str = '?a=1'
    for(var key in params){
        str += '&' + key + '=' + params[key]
    }

    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: apiUrl + '/temperature-monitor-center/district/studentCardTemperature/exportExcel' + str,
            params,
           
        }).then(res => {
            resolve(res)
        })
    })
    
  
  }

/**
* 学生证测温数据-分页获取学生证测温数据数据
* @param {*} params 
* @returns 
*/
export const studentCardTemperaturePage = (params) => {

    return new Promise((resolve,reject) => {
        axios({
            url: appUrl + '/school/api/dashboard/antiepidemic/studentCardTemperaturePage',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch(() => {
            reject()
        })
    })
  }

//健康监测-学生证测温数据-导出文件
export const studentCardTemperatureExcel = (params) => {
  var str = '?a=1'
  for(var key in params){
      str += '&' + key + '=' + params[key]
  }
  
  window.open(appUrl + '/school/api/dashboard/antiepidemic/studentCardTemperatureExcel' + str)
}

/**大屏-体温异常数据 */
export const getTemperatureErrorData = (eduId) => {
    return new Promise((resolve, reject) => {
        const path = "/district-command-center/epidemicPrevention/getTemperatureErrorData"
        axios({
            method: "get",
            url: `${apiUrl}${path}?eduId=${eduId}`,
            data: {},
            headers: {
                "content-type": "application/x-www-form-urlencoded"
            }
        }).then(res => {
            resolve(res)
        })
    })
}

/**大屏/首页-今日行程卡数据 */
export const getTodayItineraryCardData = (eduId) => {
    return new Promise((resolve, reject) => {
        const path = "/district-command-center/epidemicPrevention/getTodayItineraryCardData"
        axios({
            method: "get",
            url: `${apiUrl}${path}?eduId=${eduId}`,
            data: {},
            headers: {
                "content-type": "application/x-www-form-urlencoded"
            }
        }).then(res => {
            resolve(res)
        })
    })
}

/**健康监测 - 首页 */
export const $home = {
    /**数据统计 */
    todayEarlyData(eduId) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/homeStatistics"
            axios({
                method: "get",
                url: `${apiUrl}${path}?eduId=${eduId}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => {
                resolve(res)
            })
        })
    },
    /**上报数据 */
    getReportList({ id, date, page, limit }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/homeReportedPage"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${date}&page=${page}&limit=${limit}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => {
                resolve(res)
            })
        })
    },
    /**闸机数据 */
    getHardwareList({ id, date, page, limit }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/homeHardwarePage"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${date}&page=${page}&limit=${limit}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => {
                resolve(res)
            })
        })
    },
    /**行程卡数据 */
    getIneraryCardList(params) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/hometIneraryCardPage"
            axios({
                method: "get",
                url: `${apiUrl}${path}`,
                params
            }).then(res => {
                resolve(res)
            })
        })
    },
    getIneraryCardParentList(params) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/hometIneraryCardParentPage"
            axios({
                method: "get",
                url: `${apiUrl}${path}`,
                params
            }).then(res => {
                resolve(res)
            })
        })
    },
}

/**健康监测 - 上报数据 */
export const $reported = {
    getList({ date, id }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/getReportedList"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => {
                resolve(res)
            })
        })
    },
    getDetail({ id, page = 1, limit = 10, date }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/getReportedPage"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}&page=${page}&limit=${limit}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => {
                resolve(res)
            })
        })
    },
    exportList({ id, date }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/exportReportedList"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                },
                responseType: "arraybuffer",
            }).then(res => {
                resolve(res)
            })
        })
    },
    exportDetail({ id, date, schoolName }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/exportReportedPage"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}&schoolName=${schoolName}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                },
                responseType: "arraybuffer",
            }).then(res => {
                resolve(res)
            })
        })
    }
}

/**健康监测 - 闸机数据 */
export const $hardware = {
    getList({ date, id }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/getHardwareList"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => {
                resolve(res)
            })
        })
    },
    getDetail({ id, page = 1, limit = 10, date }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/getHardwarePage"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}&page=${page}&limit=${limit}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => {
                resolve(res)
            })
        })
    },
    exportList({ id, date }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/exportHardwareList"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                },
                responseType: "arraybuffer",
            }).then(res => {
                resolve(res)
            })
        })
    },
    exportDetail({ id, date, page, limit }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/exportHardwarePage"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}&page=${page}&limit=${limit}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                },
                responseType: "arraybuffer",
            }).then(res => {
                resolve(res)
            })
        })
    }
}

/**健康监测 - 行程卡 */
export const $itineraryCard = {
    getList({ date, id }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/getItineraryCardList"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => {
                resolve(res)
            })
        })
    },
    exportList({ id, date }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/exportItineraryCardList"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                },
                responseType: "arraybuffer",
            }).then(res => {
                resolve(res)
            })
        })
    },
    getChildrens(params) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/getItineraryCardPage"
            axios({
                method: "get",
                url: `${apiUrl}${path}`,
                params
            }).then(res => {
                resolve(res)
            })
        })
    },
    getPatriarchs(params) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/getItineraryCardParentPage"
            axios({
                method: "get",
                url: `${apiUrl}${path}`,
                params
            }).then(res => {
                resolve(res)
            })
        })
    },
    getChildrensByPatriarch(params) {
        return new Promise((resolve, reject) => {
            const path = "/api/app/parent-risk-area/user-children"
            axios({
                method: "get",
                url: `${cardUrl}${path}`,
                params
            }).then(res => {
                resolve(res)
            })
        })
    },
    exportDetail({ id, date }) {
        return new Promise((resolve, reject) => {
            const path = "/district-command-center/epidemicPrevention/exportItineraryCardPage"
            axios({
                method: "get",
                url: `${apiUrl}${path}?id=${id}&date=${util.formatting(date, "YYYY-MM-DD")}`,
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                },
                responseType: "arraybuffer",
            }).then(res => {
                resolve(res)
            })
        })
    },
}

/**轨迹 */
export const $Journey = {
    /**获取单个用户时间段内设备GPS信息 */
    getPathInfo(params) {
        params.PageNum = 1
        params.PageSize = 9999
        return new Promise((resolve, reject) => {
            const path = "/api/app/record-risk-user/command-ceneter-gps-list-from-es";
            axios({
                url: `${cardUrl}${path}`,
                method: "get",
                params
            }).then(res => { resolve(res) })
        })
    },
    /**ES分页获取某个微信用户的历史轨迹数据 */
    getWxUserPathInfo(params) {
        params.PageNum = 1
        params.PageSize = 9999
        return new Promise((resolve, reject) => {
            const path = "/api/app/record-risk-parent/command-ceneter-user-location-list-from-es";
            axios({
                url: `${cardUrl}${path}`,
                method: "get",
                params
            }).then(res => { resolve(res) })
        })
    },
    /**获取-家长当前风险信息 */
    getWxUserCardInfo({ UserId }) {
        return new Promise((resolve, reject) => {
            const path = "/api/app/parent-risk-area/parent-current-risk-area/";
            axios({
                url: `${cardUrl}${path}${UserId}`,
                method: "",
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => { resolve(res) }).catch(err => { reject(err) })
        })
    },
    /**行程卡信息 */
    getCardInfo({ UserId }) {
        return new Promise((resolve, reject) => {
            const path = "/api/app/user-risk-area/user-current-risk-area-for-admin/";
            axios({
                url: `${cardUrl}${path}${UserId}`,
                method: "",
                data: {},
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then(res => { resolve(res) }).catch(err => { reject(err) })
        })
    },

    // 首页&其他页 --- 获取行程卡数据 (学生)
    getHomeTripApi(params){
        return new Promise((resolve, reject) => {
            axios({
                url: appUrl + '/school/api/dashboard/antiepidemic/reportTravelCardPage',
                method: 'get',
                params
            }).then(res => {
                resolve(res)
            })
        })
    },
    // 首页&其他页 --- 获取行程卡数据 (家长)
    getParentTripApi(params) {
        return new Promise((resolve, reject) => {
            axios({
                url: appUrl + '/school/api/dashboard/antiepidemic/parentReportTravelCardPage',
                method: 'get',
                params
            }).then(res => {
                resolve(res)
            })
        })
    },

    //健康监测-学生行程卡数据-导出
    reportTravelCardExcel(params) {
        var str = '?a=1'
        for(var key in params){
            str += '&' + key + '=' + params[key]
        }
        
        window.open(appUrl + '/school/api/dashboard/antiepidemic/reportTravelCardExcel' + str)
    }
}