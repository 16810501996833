<template>
  <div>
    <div class="total-p">
      <div class="total-item">
        <div class="h-text">历史监测数据：</div>
        <div class="h-num">{{ sumHistory }}</div>
        <!-- <div class="h-date">（2021年10月11日）</div> -->
        <div class="data-list">
          <div class="data-item">
            <div class="data-num">{{history.studentCardTemperatureCount}}</div>
            <div class="data-text">历史学生证测温</div>
          </div>
          <div class="data-item">
            <div class="data-num">{{ history.historyReport }}</div>
            <div class="data-text">历史上报</div>
          </div>
          <div class="data-item">
            <div class="data-num">{{ history.historyDevice }}</div>
            <div class="data-text">历史闸机</div>
          </div>
          <div class="data-item">
            <div class="data-num">{{ history.historyVisitor }}</div>
            <div class="data-text">历史访客</div>
          </div>
        </div>
      </div>
      <div class="total-item">
        <div class="h-text">今日预警数据：</div>
        <div class="h-num">{{ sumTodayDate }}</div>
        <div class="h-date">
          （{{ todayDate.time | formatDateTime("yyyy年MM月dd日") }}）
        </div>
        <div class="data-list">
          <div class="data-item">
            <div class="data-num">{{todayDate.temp}}</div>
            <div class="data-text">学生证测温异常</div>
          </div>
          <div class="data-item">
            <div class="data-num">{{ todayDate.up }}</div>
            <div class="data-text">上报体温异常</div>
          </div>
          <div class="data-item">
            <div class="data-num">{{ todayDate.hard }}</div>
            <div class="data-text">闸机异常</div>
          </div>
          <!-- <div class="data-item">
            <div class="data-num">{{ todayDate.card }}</div>
            <div class="data-text">行程卡异常</div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="table-p">
      <div class="tab-title">学生证测温数据</div>
      <indexCardTable
        :date="todayDate.time | formatDateTime('yyyy-MM-dd')"
      ></indexCardTable>
      <div class="tab-title">上报数据</div>
      <indexUpTable
        :date="todayDate.time | formatDateTime('yyyy-MM-dd')"
      ></indexUpTable>
      <div class="tab-title">闸机数据</div>
      <indexHardTable
        :date="todayDate.time | formatDateTime('yyyy-MM-dd')"
      ></indexHardTable>
      <!-- <div class="tab-title">行程卡数据</div>
      <indexTripTable
        :date="todayDate.time | formatDateTime('yyyy-MM-dd')"
      ></indexTripTable> -->
    </div>
  </div>
</template>
<script>
import {
  getHisData, tempTodayabnormalCount, tempHistoryCount,
  getTodayItineraryCardData,
  $home,
} from "@/api/defenceApi.js";
import indexUpTable from "./indexUpTable.vue";
import indexTripTable from "./indexTripTable.vue";
import indexHardTable from "./indexHardTable.vue";
import indexCardTable from './indexCardTable.vue'
import { refreshTime } from "@/config/config";
export default {
    components: {
      indexUpTable,
      indexTripTable,
      indexHardTable,
      indexCardTable
    },
   props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modalShow(e) {
      e && this.init();
    },
  },
  computed: {
    sumHistory() {
      const { historyReport, historyDevice, historyVisitor, studentCardTemperatureCount } = this.history;
      return historyReport + historyDevice + historyVisitor + studentCardTemperatureCount
    },
    sumTodayDate() {
      const { up, hard, card, temp } = this.todayDate;
      return up + hard + card + temp;
    },
    countyUserInfo() {
      return this.$store.state.user.countyUserInfo;
    },
  },

  data() {
    return {
      history: {
        historyReport: 0,
        historyDevice: 0,
        historyVisitor: 0,
        studentCardTemperatureCount: 0
      },
      timer: null,
      todayDate: {
        time: new Date(),
        up: 0,
        hard: 0,
        card: 0,
        temp: 0
      },
    };
  },
 
  methods: {
    init() {
      this.getHistoryData();
      this.getTodayEarlyData();
      // this.refresh();
    },
    /**获取历史监测防疫数据 */
    async getHistoryData() {
      let res = await getHisData(
        this.countyUserInfo.organizationIdStr
      );
      this.history.historyReport = res.data.historyReport || 0;
      this.history.historyDevice = res.data.historyDevice || 0;
      this.history.historyVisitor = res.data.historyVisitor || 0;
      this.history.studentCardTemperatureCount = res.data.studentCardTemperatureCount || 0;
    
    },
    async getTodayEarlyData() {
    
      let res =  await $home.todayEarlyData(this.countyUserInfo.organizationIdStr);
      this.todayDate.up = res.data.reportAbnormal;
      this.todayDate.hard = res.data.deviceAbnormal;

     
      res =  await getTodayItineraryCardData(this.countyUserInfo.organizationIdStr);
      this.todayDate.card = res.data.abnormalCount

      res = await tempTodayabnormalCount({
        districtId: this.countyUserInfo.organizationIdStr
      })
      this.todayDate.temp = res.data
      
    },



    refresh() {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.todayDate.time = new Date();
        this.getHistoryData();
        this.getTodayEarlyData();
        this.refresh();
      }, refreshTime);
    },
  },
  
};
</script>
<style scoped>
.total-p {
  display: flex;
  justify-content: space-between;
  padding: 0.26rem 0 0 0;
}
.total-item {
  width: 5.2rem;
  height: 1.44rem;
  background: rgba(29, 51, 110, 0.2);
  padding: 0.25rem;
  box-sizing: border-box;
}
.h-text {
  color: #fff;
  font-size: 0.16rem;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}
.h-num {
  color: #ffa800;
  font-size: 0.16rem;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}
.h-date {
  color: #b8c8e9;
  font-size: 0.14rem;
  float: right;
}
.data-list {
  display: flex;
  justify-content: space-around;
  clear: both;
  padding: 0.3rem 0 0 0;
}
.data-item {
  width: 1.2rem;
  text-align: center;
}
.data-num {
  color: #00f9ff;
  font-size: 0.18rem;
  font-weight: bold;
}
.data-text {
  color: #b8c8e9;
  font-size: 0.14rem;
}
.tab-title {
  width: 1.2rem;
  height: 0.34rem;
  line-height: 0.34rem;
  text-align: center;
  background: linear-gradient(
    to right,
    rgba(13, 57, 108, 1),
    rgba(13, 57, 108, 0)
  );
  color: rgba(0, 162, 255, 1);
  font-size: 0.16rem;
  font-weight: bold;
  margin: 0.34rem 0 0 0;
}
.table-p {
  margin: 0.35rem 0 0 0;
  height: 4.2rem;
  overflow-y: scroll;
}
</style>