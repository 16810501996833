<template>
  <div class="upData">
    <div class="form-p">
      <el-date-picker
        v-model="date"
        type="date"
        placeholder="选择日期"
        :picker-options="{
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6;
          },
        }"
      >
      </el-date-picker>
      <el-button
        type="primary"
        v-if="tableData.length > 0"
        @click="exportExcel"
      >
        按检索结果导出
      </el-button>
    </div>
    <cvue-table
      :option="tableOption"
      :height="tableOption.height"
      :data="tableData"
      ref="tbl1"
      :isShowAdd="false"
      :page="page"
      :tablePage="page.currentPage"
      :pageSize="page.pageSize"
      :loading="tableLoading"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :isShowPage="false"
    >
      <!-- 操作按钮 -->
      <el-table-column
        slot="actionMenu"
        label="操作"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <div class="operation">
            <el-button
              type="text"
              @click="handLook(scope.row)"
              style="font-size: 0.16rem"
              >查看</el-button
            >
          </div>
        </template>
      </el-table-column>
    </cvue-table>

    <lookUpData
      :modelShow="lookShow"
      :id="selectedCloumn.schoolId"
      :schoolName="selectedCloumn.schoolName"
      :date="date | formatDateTime('yyyy-MM-dd')"
      @handelClose="lookShow = false"
    ></lookUpData>
  </div>
</template>
<script>
import { $reported } from "@/api/defenceApi.js";
import cvueTable from "@/components/cvue-table";
import lookUpData from "./lookUpData.vue";
import util from "@/util/timeFormat.js";

export default {
  data() {
    return {
      tableLoading: false,
      page: {
        total: 0, // 总条目
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50],
      },

      tableOption: {
        stripe: true,
        width: "100%",
        height: "5.5rem",
        border: false,
        index: false,
        cloumn: [
          { prop: "schoolName", label: "学校名称", align: "center" },
          { prop: "reportedNum", label: "上报数据量", align: "center" },
          {
            prop: "participateReportedRatio",
            label: "参与上报比",
            align: "center",
          },
          { prop: "reportedRatio", label: "已上报比", align: "center" },
          { prop: "errorNum", label: "上报体温异常数量", align: "center" },
        ],
      },
      tableData: [],
      selectedCloumn: { schoolId: "", schoolName: "" },
      date: new Date(),
      lookShow: false,
    };
  },
  methods: {
    // 改变pagesize
    handleSizeChange(val) {
      this.page.pageSize = val;
    },
    // 改变页码
    handleCurrentChange(val) {
      this.page.currentPage = val;
     
    },
    handLook(item) {
      this.selectedCloumn = item;
      this.lookShow = true;
    },
    async getSchoolList() {
      try {
        this.tableLoading = true;
        const { status, data } = await $reported.getList({
          date: this.date,
          id: this.countyUserInfo.organizationIdStr,
        });
        this.tableLoading = false;
        // console.log("健康监测-上报数据列表", status, data);
        if (
          (status === 0 || status === 200) &&
          data &&
          typeof data === "object"
        ) {
          data.forEach((e) => {
            e.reportedNum = e.reportedNum || 0;
            e.participateReportedRatio = e.participateReportedRatio || "0%";
            e.reportedRatio = e.reportedRatio || "0%";
            e.errorNum = e.errorNum || 0;
          });
          this.tableData = data;
        }
      } catch {
        this.tableLoading = false;
      }
    },
    async exportExcel() {
      if (this.tableData.length === 0) {
        this.$message.error(res.msg || "暂无导出数据");
        return;
      }
      const res = await $reported.exportList({
        id: this.countyUserInfo.organizationIdStr,
        date: this.date,
      });
      // console.log("健康监测-上报数据列表导出", res);
      const down = document.createElement("a");
      const blob = new Blob([res], { type: "application/vnd.ms-excel" });
      down.href = URL.createObjectURL(blob);
      down.download = `健康监测-学校上报数据-${util.formatting(
        this.date,
        "YYYY年MM月DD日"
      )}.xls`;
      down.target = "_blank";
      console.log(down);
      down.click();
    },
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    date(e) {
      if (e) {
        if (e > new Date()) {
          this.date = new Date();
          return;
        }
        this.getSchoolList();
      } else {
        this.tableData = new Array();
      }
    },
    modalShow(e) {
      if (e) {
        const now = new Date();
        this.date === now && this.getSchoolList();
        this.date !== now && (this.date = now);
      }
    },
  },
  computed: {
    countyUserInfo() {
      return this.$store.state.user.countyUserInfo;
    },
    tableHeight() {},
  },
  components: {
    cvueTable,
    lookUpData,
  },
};
</script>
<style scoped>
.form-p {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style lang="scss">
.upData {
  .el-table {
    height: 5.5rem;
    overflow-y: auto;

    .el-table__header-wrapper {
      height: 0.5rem;
    }

    .el-table__body-wrapper {
      height: 5rem;
      overflow-y: auto;
    }
  }
}
</style>