import axios from './axios'
import qs from 'qs'
import {appUrl} from '@/config/config'


export const getGpsList = (params) => {
    params.pageNum = 1
    params.pageSize = 10000
    return new Promise((resolve,reject) => {
        axios({
            url: appUrl + '/safety/api/position/gPSService/gpsListFromEs',
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch(() => {
            reject()
        })
    })
}

export const getGpsPosition = (studentId) => {

    return new Promise((resolve,reject) => {
        axios.noLoading = true
        axios({
            url: appUrl + '/safety/api/position/gPSService/lastGPSInfoFromEs/' + studentId,
            method: 'get'
        }).then(res => {
            resolve(res)
        }).catch(() => {
            reject()
        })
    })
}