<template>
<div class="cvue-table">
    <div class="table-wrap">
        <el-table :stripe="option.stripe"
                    :width="option.width"
                    :border="option.border"
                    ref="homeTable"
                    :class="cssClass"
                    class="home-table"
                    v-loading="loading"
                    element-loading-background="rgba(0, 0, 0, 0.5)"
                    @cell-click="handleClick"
                    :data="data"
                    cell-class-name='t-tr-cell'
                    header-cell-class-name='t-header-cell'>
            <!-- 复选框 -->
            <el-table-column type="selection" width="55"  v-if="option.selected"  align="center" header-align="center"></el-table-column>
            <!-- 序号 -->
            <el-table-column  type="index" :width="indexWidth" v-if="option.index" align="center" label="序号"></el-table-column>
            <!-- 循环列 -->
            <el-table-column :prop="cloumn.prop"
                            :label="cloumn.label"
                            :width="cloumn.width"
                            :align="cloumn.align"
                            :headerAlign="cloumn.headerAlign"
                            :key="cloumn.prop"
                            v-for="cloumn in option.cloumn">
                            <template slot-scope="scope">
                                <slot v-if="cloumn.slotStatus" :name="cloumn.prop" :row="scope.row"></slot>
                                <template v-else>
                                     <span v-if="cloumn.prop=='index'">
                                        {{(scope.$index + 1) + pageSize * (tablePage - 1) }}
                                    </span>
                                    <span v-else>{{scope.row[cloumn.prop]}}</span>
                                </template>
                            </template>
            </el-table-column>
            <slot name="multipleCloumn"></slot>
            <slot name="singleCloumn"></slot>
            <!-- 操作按钮 -->
            <slot name="actionMenu"></slot>
        </el-table>
    </div>
    <div class="page" v-show="isShowPage">
        <el-pagination
            :current-page="page.currentPage"
            background
            :page-sizes="page.pageSizes"
            :page-size="page.pageSize"
            :layout="layout"
            :total="page.total"
            :pager-count="5"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange">
        </el-pagination>
    </div>
</div>
</template>
<script>
export default {
    name: 'cvueTable',
    data () {
        return {
        }
    },
    props: {
        isShowPage: {
            type: Boolean,
            default: false
        },
        page: {
            type: Object,
            default: () => {
                return {}
            }
        },
        option: {
            type: Object,
            required: true,
            default: () => {
                return {}
            }
        },
        data: {
            type: Array,
            required: true,
            default: () => {
                return []
            }
        },
        totalData: {
            type: Array,
            default: () => {
                return []
            }
        },
        cssClass: {
            type: String,
            default: ''
        },
        isIndexTotal: {
            type: Boolean,
            default: false
        },
        isShowSummary: {
            type: Boolean,
            default: false
        },
        isShowTotalSummary: {
            type: Boolean,
            default: false
        },
        summaryType: {
            type: Number,
            default: 1
        },
        indexWidth: {
            type: Number,
            default: 62
        },
        summaryFun: {
            type: Function,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        layout: {
            type: String,
            default: 'prev,pager,next,total,jumper'
        },
        tablePage: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 1
        }
    },
    methods: {
        handleCurrentChange (val) {
            this.$emit('current-change', val)
        },
        handleSizeChange (val) {
            this.$emit('size-change', val)
        },
        handleClick (row, index, prop) {
            this.$emit('cellClick', row, index, prop)
        }
    }
}
</script> 
<style scoped>
.page{
    text-align: center; padding: 0.1rem 0 0 0;
    width: fit-content;
    margin: auto;
}
</style>
<style lang="scss">
.cvue-table{
    .el-table{
    background-color: transparent !important;
}
.el-table th{
    background-color: transparent !important;
}
.el-table tr{
    background-color: transparent !important;
}
.t-header-cell{
         background-color: transparent !important;
        color: #fff;
        border-bottom: 0px solid  transparent !important;
        border-right: 0px solid  transparent !important;
        height: 0.5rem;
}
.t-tr-cell{
     background-color: transparent !important;
         color: #B8C8E9; font-size: 0.16rem;
         border-bottom: 0px solid  transparent !important;
        border-right: 0px solid  transparent !important;
          height: 0.5rem;
}
.el-table--striped .el-table__body tr.el-table__row--striped td {
    background: rgba(29, 51, 110, 0.2) !important;
}
  .el-table--group, .el-table--border {
     border-top: 0px solid  transparent !important;
      border-left: 0px solid  transparent !important;
 }
 .el-table__empty-block {
     background-color: transparent !important;
    color: #B8C8E9 !important;
 }
  .el-table__empty-text{
     color: #B8C8E9;
 }
 .el-table th > .cell{
    font-size: 0.16rem;
 }

    .el-table--group::after, .el-table--border::after, .el-table::before {
        height: 0 !important;
}



 .btn-prev, .btn-next {
  
 background:#0B1F46 !important;
   
}
.el-input__inner{
    
    color: #1082D6;
    border:1px solid #1082D6;
     background: #0B1F46 !important;
}
.el-pagination__total{
    color: #B8C8E9 !important;
}
.el-pagination__jump{
    color: #B8C8E9 !important;
}
.el-pager li{
    background: #0B1F46 !important;
    color: #A8ADB9 !important;
}
.el-pager li.active{
    background: #1082D6 !important;
  
}
}

</style>>
