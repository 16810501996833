<template>
  <modal
    :modelSize="'max'"
    :modelShow="modelShow"
    :zIndex="400"
    @open="open"
    @handelClose="$emit('handelClose')"
  >
    <template slot="body">
      
        <div class="form-p">
          <span class="form-text">选择查询轨迹时间段：</span>
          <el-date-picker
            v-model="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :clearable="false"
          >
          </el-date-picker>

          <el-button
            type="primary"
            style="margin: 0 0 0 0.1rem"
            @click="pathSearch"
          >
            查询
          </el-button>
        </div>

        <Amap ref="amap"></Amap>

        <cardImg
        
          style="transition: 0.3s"
          :openCount="openCount"
          :id="id"
          :isWxUser="isWxUser"
          class="card-img"
        ></cardImg>
     
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/modal/modal";
import cardImg from "./cardImg.vue";
import util from "@/util/timeFormat.js";
import { $Journey } from "@/api/defenceApi.js";
import Amap from '@/components/Amap'
export default {
  name: "trackMap",
   components: {
    Modal,
    cardImg,
    Amap
  },
  props: {
    modelShow: Boolean,
    modelSize: String,
    id: String,
    entrance: String,
    isWxUser: {
      type: Boolean,
      default: false,
    },
    initDate: {
      type: String,
      default: util.formatting(new Date(), "yyyy-MM-dd")
    }
  },
  data() {
    return {
      map: null,
      date: [
       
      ],
      center: [119.297175, 26.081734],
      starting: { lng: 119.297175, lat: 26.081734 },
      ending: { lng: 119.297175, lat: 26.081734 },
      path: [],
      openCount: 0,
      opacity: 0,
    };
  },
  computed: {
    mapId() {
      return `map_${this.entrance}_${this.id}`;
    },
    organizationAddress() {
      return this.$store.state.user.countyUserInfo.organizationName;
    },
  },
  methods: {
    
    async open() {
      
       await this.$refs.amap.init()
   
      this.date = [
        util.formatting(this.initDate, "yyyy-MM-dd") + " 00:00:00",
        util.formatting(this.initDate, "yyyy-MM-dd") + " 23:59:59",
      ];
     
        this.opacity = 0;
        this.pathSearch();
      
     
    },
    pathSearch() {
      this.openCount++;
      if (this.date && this.date.length > 0) {
        this.getJourneyPath();
      } else {
        this.$message.error("请选择时间范围");
       
      }
    },
   
    /**获取路线 */
    async getJourneyPath() {
      let items = []
      let totalCount = 0;
      if (this.isWxUser === true) {
        // 微信用户的情况下 （家长）
        
        const res = await $Journey.getWxUserPathInfo({
          UserId: this.id,
          BeginTime: this.date[0],
          EndTime: this.date[1],
        });
        items = res.items;
        totalCount = res.totalCount;
        var path = res.items.map(obj => {
          return {
            lng: parseFloat(obj.longitude),
            lat: parseFloat(obj.latitude),
            createTime: obj.createTime
          }
        })
      } else {
        
        // 普通用户 （学生
        const res = await $Journey.getPathInfo({
          UserId: this.id,
          BeginTime: this.date[0],
          EndTime: this.date[1],
        });
        items = res.items;
        totalCount = res.totalCount;
         var path = res.items.map(obj => {
          return {
            lng: parseFloat(obj.c_Longitude),
            lat: parseFloat(obj.c_Latitude),
            createTime: obj.createTime
          }
        })
      }

     
      
      if(path.length == 0){
        this.$message.error('没有轨迹数据')
        this.$refs.amap.clearTrack()
        return
      }
      this.$refs.amap.drawTrack({path: path})
    },
   

  },
  destroyed() {
   
  },
 
};
</script>
<style scoped>
.content {
  padding: 0.6rem;
}
.map-con {
  height: 6rem;
}
.form-p {
  padding: 0.3rem 0.6rem;
}
.form-text {
  color: #fff;
}
.card-img {
  position: absolute;
  top: 0.7rem;
  right: 0.1rem;
}
</style>

<style lang="scss">
.trackMap {
  .amap-lib-marker-to,
  .amap-lib-marker-from {
    display: none !important;
  }
}
</style> 