<template>
  <div @click="showDetail">
    <div class="box">
      <p class="partName">健康监测</p>
      <div class="content">
        <div class="data-p">
          <div class="data-tr">
            辖区历史监测数据：
            <div class="blue-t">{{ areaDetectionDateHistory.sum }}</div>
          </div>
          <div class="data-tr">
            体温异常人数：
            <div class="line-sp"></div>
            <div class="role">学生：</div>
            <div class="role-num">{{ thermometry.student }}</div>
            <div class="role">教师：</div>
            <div class="role-num">{{ thermometry.teacher }}</div>
            <div class="role">访客：</div>
            <div class="role-num">{{ thermometry.visitor }}</div>
          </div>
          <!-- <div class="data-tr">
            行程卡风险人数：
            <div class="line-sp"></div>
            <div class="role">昨日新增人数：</div>
            <div class="role-num">{{ journeyCard.risk.new }}</div>
            <div class="role">昨日留存人数：</div>
            <div class="role-num">{{ journeyCard.risk.keep }}</div>
          </div> -->
        </div>
        <div class="chart-p">
          <div class="le-tr">
            <div class="red-crilce"></div>
            未测温：{{ thermometry.notYet }}人
          </div>

        

          <div class="le-tr">
            <div class="blue-crilce"></div>
            已测温：{{ thermometry.yet }}人
          </div>
          <pie :data="thermometryProportion" class="pie-p"></pie>
          <div class="pie-text">测温比例</div>
        </div>

        <!-- <div class="chart-p">
          <div class="le-tr">
            <div class="red-crilce"></div>
            行程卡异常人数：{{ journeyCard.anomaly }}
          </div>
          <div class="le-tr">
            <div class="blue-crilce"></div>
            行程卡正常人数：{{ journeyCard.normal }}
          </div>
          <div class="le-tr">
            <div class="yellow-crilce"></div>
            无行程卡人数：{{ journeyCard.none }}
          </div>
          <pie :data="journeyCardPropoertion" class="pie-p"></pie>
          <div class="pie-text">行程卡状态比例</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import {
  getTodayItineraryCardData,
  getTemperatureErrorData,
  getHisData,
} from "@/api/defenceApi.js";
import pie from "@/components/echarts/pieDefence";
import annular from "@/components/annular";
import { refreshTime } from "@/config/config";
export default {
  data() {
    return {
      /**测温数据 */
      thermometry: {
        yet: 0, //已经
        notYet: 0, //尚未
        student: 0,
        teacher: 0,
        visitor: 0,
        sum: 0,
      },
      /**行程卡数据 */
      journeyCard: {
        normal: 0, //正常
        anomaly: 0, //异常
        none: 0, //无
        risk: {
          new: 0,
          keep: 0,
        },
      },
      /**历史 */
      areaDetectionDateHistory: {
        sum: 0,
      },
    };
  },
  computed: {
    countyUserInfo() {
      return this.$store.state.user.countyUserInfo;
    },
    /**测温比例 */
    thermometryProportion() {
      const danger = {
        name: "未测温人数",
        color: "#FF8087",
        value: this.thermometry.notYet,
      };
      const safety = {
        name: "测温人数",
        color: "#3891FF",
        value: this.thermometry.yet,
      };
      return [danger, safety];
    },
    /**行程卡状态比例 */
    journeyCardPropoertion() {
      const danger = {
        name: "行程卡异常人数",
        color: "#FF8087",
        value: this.journeyCard.anomaly,
      };
      const safety = {
        name: "行程卡正常人数",
        color: "#3891FF",
        value: this.journeyCard.normal,
      };
      const warning = {
        name: "无行程卡人数",
        color: "#f9a333",
        value: this.journeyCard.none,
      };
      return [danger, safety, warning];
    },
  },

  methods: {
    showDetail() {
      this.$store.state.county.defenceModalTabIndex = 0
      this.$store.state.county.defenceModalShow = true;
    },
    getData() {
      this.getJourneyCard();
      this.getThermometry();
      this.getHisData();
    },
    /**行程卡 */
    async getJourneyCard() {
      const eduId = this.countyUserInfo.organizationIdStr;
      const { status: journeyCardStatus, data: journeyCard } =
        await getTodayItineraryCardData(eduId);
      if (
        (journeyCardStatus === 200 || journeyCardStatus === 0) &&
        journeyCard &&
        typeof journeyCard === "object"
      ) {
        this.journeyCard.anomaly = journeyCard.abnormalCount;
        this.journeyCard.normal = journeyCard.normalCount;
        this.journeyCard.none = journeyCard.nullCount;
        this.journeyCard.risk.new = journeyCard.incomingCount;
        this.journeyCard.risk.keep = journeyCard.leftCount;
      }
    },
    /**体温异常人数 */
    async getThermometry() {
      const eduId = this.countyUserInfo.organizationIdStr;
      const { status, data } = await getTemperatureErrorData(eduId);
      // console.log("大屏-健康监测-体温异常人数", status, data);
      if (
        (status === 200 || status === 0) &&
        data &&
        typeof data === "object"
      ) {
        this.thermometry.sum = data.eduNum || 0; // 总
        this.thermometry.student = data.studentNum || 0; // 学生
        this.thermometry.teacher = data.teacherNum || 0; // 教师
        this.thermometry.yet = data.temperatureNum || 0; // 已测
        this.thermometry.visitor = data.visitorNum || 0; // 访客
        this.thermometry.notYet =
          (data.eduNum || 0) - (data.temperatureNum || 0); // 未测
      }
    },
    /**辖区历史监测数据 */
    async getHisData() {
      const eduId = this.countyUserInfo.organizationIdStr;
      const { status, data } = await getHisData(eduId);
      // console.log("大屏-健康监测-辖区历史监测数据", status, data);
      if (
        (status === 200 || status === 0) &&
        data &&
        typeof data === "object"
      ) {
        this.areaDetectionDateHistory.sum =
          data.historyDevice 
          + data.historyReport 
          + data.historyVisitor 
          + (data.studentCardTemperatureCount || 0)
      }
    },
    refresh() {
      setTimeout(() => {
        this.getData();
        this.refresh();
      }, refreshTime);
    },
  },
  async mounted() {
    this.getData();
    this.refresh();
  },
  components: {
    pie,
    annular,
  },
};
</script>
<style scoped>
* {
  color: #fff;
  font-size: 0.14rem;
}
.box {
  padding: 0 0.1rem;
  width: 5.04rem;
  height: 2.44rem;
  background-image: url("./img/smallBk.png");
  background-size: 100%;
  box-sizing: border-box;

  cursor: pointer;
}
.partName {
  text-align: center;
  font-weight: bold;
  font-size: 0.16rem;
}
.content {
  padding: 0.3rem 0 0 0; display: flex; justify-content: space-around;
}

.data-p {

  width: 2.2rem;
}
.data-tr {
  color: #fff;
  font-size: 0.14rem;
  margin: 0.25rem 0;
}
.role {
  display: inline-block;
  vertical-align: middle;
  color: #00a2ff;
  font-size: 0.14rem;
}
.blue-t {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.14rem;
  color: #00f9ff;
  font-weight: bold;
}
.role-num {
  display: inline-block;
  vertical-align: middle;
  width: 0.3rem;
  font-size: 0.14rem;
  color: #00f9ff;
  font-weight: bold;
}
.chart-p {

  width: 2.3rem;
  text-align: center;
}
.pie-p {
  width: 1rem;
  height: 1rem;
  margin: 0.04rem auto 0 auto;
}
.sbbi {
  text-align: center;
  color: #00a2ff;
}
.le-tr {
  font-size: 0.01rem;
  color: #b1cef4;
  padding: 0 0 0.06rem 0;
  display: inline; vertical-align: middle;
  margin: 0 0.1rem 0;
}
.le-spa {
  height: 0.24rem;
}
.line-sp {
  height: 0.14rem;
}
.red-crilce {
  display: inline-block;
  vertical-align: middle;
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  background: #ff8087;
}
.blue-crilce {
  display: inline-block;
  vertical-align: middle;
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  background: #3891ff;
}
.yellow-crilce {
  display: inline-block;
  vertical-align: middle;
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  background: #f9a333;
}
.pie-text {
  font-size: 0.14rem;
  color: #00a2ff;
  text-align: center;
  padding: 0.1rem 0 0 0;
}
</style>