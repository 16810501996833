<template>
  <div class="children-table">
    <optionBar :schoolId="id" :showShowMode="false" 
          @change="changeOptionBar"  @queryClick="queryClick" @excelClick="exportExcel"></optionBar>

    <cvue-table class="list-p"
      :option="tableOption"
      :data="tableData"
      ref="children"
      :isShowAdd="false"
      :page="page"
      :tablePage="page.currentPage"
      :pageSize="page.pageSize"
      :loading="tableLoading"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :isShowPage="page.total > 0 && tableData.length > 0"
    >
      <!-- 操作按钮 -->
      <el-table-column
        slot="actionMenu"
        label="操作"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <div class="operation">
            <el-button
              type="text"
              @click="handLook(scope.row)"
              style="font-size: 0.16rem"
            >
              查看
            </el-button>
          </div>
        </template>
      </el-table-column>
    </cvue-table>
  </div>
</template>

<script>
import cvueTable from "@/components/cvue-table";
import { $itineraryCard, $Journey } from "@/api/defenceApi.js";
import util from "@/util/timeFormat.js";
import optionBar from '@/components/optionBar'
export default {
  data() {
    return {
      tableLoading: false,
      tableOption: {
        stripe: true,
        width: "100%",
        border: false,
        index: false,
        cloumn: [
          { prop: "date", label: "时间", align: "center" },
          { prop: "studentName", label: "姓名", align: "center" },
          { prop: "gradeName", label: "年级	", align: "center" },
          { prop: "className", label: "班级", align: "center" },
          { prop: "deviceNo", label: "学生证设备号", align: "center" },
          { prop: "result", label: "监测结果", align: "center" },
        ],
      },
      tableData: [],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50],
      },
    };
  },
  mounted(){
  
  },
  methods: {
   

     changeOptionBar(params){
          
        this.clas = params.clas
        this.grade = params.grade
        this.riskCardState = params.riskCardState
     
      }, 
      queryClick(){
     
        this.page.currentPage = 1;
        this.getTableData();
      }, 


    async getTableData() {
        this.tableLoading = true;
        let params = {
          Date: this.date,
          Id: this.id,
          Limit: this.page.pageSize,
          Page: this.page.currentPage,
         
          riskCardState: this.riskCardState,
          gradeId: this.grade.id,
          classId: this.clas.id
        }
         let list = await $Journey.getHomeTripApi(params)
         for (let i of list.records)  i.riskAreaText = i.riskArea.join('，')
         this.page.total = list.total
         this.tableData = list.records

         this.tableLoading = false;    
    },
    /**导出 */
    async exportExcel() {
      
      let params = {
            Date: this.date,
            Id: this.id,
            Limit: this.page.pageSize,
            Page: this.page.currentPage,
          
            riskCardState: this.riskCardState,
          
          }
          if(this.grade){
            params.gradeId = this.grade.id
          }
          if(this.clas){
            params.classId = this.clas.id
          }
          $Journey.reportTravelCardExcel(params)
    },
    /**查看行程卡 */
    async handLook(item) {
      try {
        const data = await $Journey.getCardInfo({ UserId: item.userId });
        if (data && typeof data === "object" && data.id) {
          this.$emit("lookcard", item);
        } else {
          throw { msg: "不存在行程数据" };
        }
      } catch (err) {
        this.$message.error(`${item.name}不存在行程数据`);
      }
    },
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.getTableData();
    },
    handleSizeChange() {},
  },

  computed: {
     countyUserInfo() {
      return this.$store.state.user.countyUserInfo;
    },
  },
  props: {
    modelShow: Boolean,
    date: String,
    id: String,
    schoolName: String,
  },
  components: { cvueTable, optionBar },
};
</script>

<style lang="scss" scoped>
.children-table {
  position: relative;

  .export-btn {
    position: absolute;
    top: -0.6rem;
    right: 0.2rem;
  }
}
.list-p{
  margin: 0.1rem 0 0 0;
}
</style>